import React, { useState, useEffect } from "react";
import AssetFinanceCalculator from "./components/AssetFinanceCalculator";
import Fees from "./components/Fees";
import LoanSummary from "./components/LoanSummary";
import Payments from "./components/Payments";
import Desktop from "./desktop";

function LoanCalculatorV3() {
  const [showPayments, setShowPayments] = useState(false);
  const [showFees, setShowFees] = useState(false);
  const [showLoanSummary, setShowLoanSummary] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);

  const [formData, setFormData] = useState({
    // Asset Finance Calculator fields
    purchasePrice: "",
    cashDeposit: "",
    tradeIn: "",
    payoutAmount: "",
    interestRate: "",

    // Payments fields
    loanTerm: "",
    balloonPayment: "",
    balloonPaymentPercentage: "",
    paymentFrequency: "Monthly",
    paymentTiming: "Advance",

    // Fees fields
    brokerage: "",
    brokeragePercentage: "",
    loanApplicationFee: "",
    brokerOriginationFee: "",
    financeBrokerFee: false,
    financeLenderFee: false,
  });

  const [navigationStack, setNavigationStack] = useState(["calculator"]);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Handler for updating form data
  const handleFormDataChange = (newData) => {
    console.log("Updating form data in LoanCalculatorV3:", newData);
    setFormData((prevData) => {
      const updatedData = { ...prevData, ...newData };
      console.log("Updated form data in LoanCalculatorV3:", updatedData);
      return updatedData;
    });
  };

  // Handlers for page visibility
  const handleShowPayments = () => {
    setShowPayments(true);
    setShowFees(false);
    setShowLoanSummary(false);
    setNavigationStack((prev) => [...prev, "payments"]);
  };

  // const handleHidePayments = () => {
  //   setShowPayments(false);
  // };

  const handleShowFees = () => {
    setShowFees(true);
    setShowPayments(false);
    setShowLoanSummary(false);
    setNavigationStack((prev) => [...prev, "fees"]);
  };

  // const handleHideFees = () => {
  //   setShowFees(false);
  //   setShowPayments(true);
  // };

  const handleShowLoanSummary = () => {
    setShowLoanSummary(true);
    setShowFees(false);
    setShowPayments(false);
    setNavigationStack((prev) => [...prev, "summary"]);
  };

  // const handleHideLoanSummary = () => {
  //   setShowLoanSummary(false);
  //   setShowFees(true);
  // };

  const handleBack = () => {
    if (navigationStack.length > 1) {
      const newStack = [...navigationStack];
      newStack.pop();
      setNavigationStack(newStack);
      const previousScreen = newStack[newStack.length - 1];

      setShowPayments(previousScreen === "payments");
      setShowFees(previousScreen === "fees");
      setShowLoanSummary(previousScreen === "summary");
    }
  };

  return (
    <div className="app-container quote-calc">
      {isDesktop && <Desktop />}

      {!isDesktop && (
        <>
          {navigationStack[navigationStack.length - 1] === "calculator" && (
            <AssetFinanceCalculator
              onShowPayments={handleShowPayments}
              formData={formData}
              onFormDataChange={handleFormDataChange}
            />
          )}

          {showPayments && (
            <div id="payments">
              <Payments
                onBack={handleBack}
                onShowFees={handleShowFees}
                formData={formData}
                onFormDataChange={handleFormDataChange}
              />
            </div>
          )}

          {showFees && (
            <div id="fees">
              <Fees
                onBack={handleBack}
                onShowLoanSummary={handleShowLoanSummary}
                formData={formData}
                onFormDataChange={handleFormDataChange}
              />
            </div>
          )}

          {showLoanSummary && (
            <div id="loan-summary">
              <LoanSummary onBack={handleBack} formData={formData} />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default LoanCalculatorV3;

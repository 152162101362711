import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ToggleSwitch from "./ToggleSwitch";

const InputField = ({
  name,
  label,
  type,
  options,
  format,
  value,
  checked,
  onChange,
  onBlur,
  toggle,
}) => {
  const [localValue, setLocalValue] = useState(value || "");
  const inputId = `${label.replace(/\s+/g, "-").toLowerCase()}-input`;

  useEffect(() => {
    setLocalValue(value || "");
  }, [value]);

  const cleanInput = (input, isPercentage) => {
    if (input === undefined || input === null) return "";
    let cleaned = input.toString().replace(/[^0-9.]/g, "");
    if (isPercentage) {
      const numValue = parseFloat(cleaned);
      if (!isNaN(numValue)) {
        cleaned = Math.min(100, numValue).toString();
      }
    }
    return cleaned;
  };

  const formatNumberWithCommas = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleChange = (e) => {
    if (type === "checkbox") {
      onChange(name, e.target.checked);
    } else if (type === "radio") {
      onChange(name, e.target.value);
    } else {
      onChange(e);
    }
  };

  const handleBlur = () => {
    let formattedValue = localValue;
    if (format === "money" && localValue) {
      const numericValue = parseFloat(cleanInput(localValue, false));
      if (!isNaN(numericValue)) {
        formattedValue = `$${formatNumberWithCommas(numericValue.toFixed(2))}`;
      } else {
        formattedValue = "";
      }
    } else if (format === "percentage" && localValue) {
      const numericValue = parseFloat(cleanInput(localValue, true));
      if (!isNaN(numericValue)) {
        formattedValue = `${numericValue.toFixed(2)}%`;
      } else {
        formattedValue = "";
      }
    }
    setLocalValue(formattedValue);

    const syntheticEvent = {
      target: {
        name: name,
        value: formattedValue,
        type: type,
      },
    };

    onChange(syntheticEvent);
    if (onBlur) {
      onBlur(syntheticEvent);
    }
  };

  const handleFocus = () => {
    if (format === "money" || format === "percentage") {
      const cleanedValue = cleanInput(localValue, format === "percentage");
      setLocalValue(cleanedValue === "0" ? "" : cleanedValue);
    }
  };

  const getPlaceholder = () => {
    if (format === "money") return "$";
    if (format === "percentage") return "%";
    return "";
  };

  const renderRadioButtons = () => {
    return (
      <div className="radio-group">
        {options.map((option, index) => (
          <label key={index} className="radio-label">
            <input
              type="radio"
              name={name}
              value={option.value}
              checked={localValue === option.value}
              onChange={handleChange}
              className="radio-input"
            />
            <span className="radio-custom">{option.label}</span>
          </label>
        ))}
      </div>
    );
  };

  return (
    <div className="frame-2">
      <label className="text-wrapper" htmlFor={inputId}>
        {label}
      </label>
      {type === "checkbox" ? (
        <label className="checkbox-label" htmlFor={inputId}>
          <input
            id={inputId}
            name={name}
            type="checkbox"
            className="checkbox-input"
            checked={checked}
            onChange={(e) => onChange(name, e.target.checked)}
          />
          <span className="checkbox-custom"></span>
          <span className="checkbox-text">{label}</span>
        </label>
      ) : type === "toggle" && toggle ? (
        <ToggleSwitch
          options={toggle.options}
          initialOption={toggle.initialOption}
          onChange={(value) => onChange(name, value)}
          name={name}
        />
      ) : type === "select" ? (
        <select
          id={inputId}
          name={name}
          className="rectangle-select"
          value={localValue}
          onChange={handleChange}
        >
          {options &&
            options.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
        </select>
      ) : type === "radio" ? (
        renderRadioButtons()
      ) : (
        <input
          id={inputId}
          name={name}
          className={`rectangle ${format ? `format-${format}` : ""}`}
          type="text"
          value={localValue}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          placeholder={getPlaceholder()}
        />
      )}
    </div>
  );
};

InputField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  options: PropTypes.array,
  format: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
  ]),
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  toggle: PropTypes.object,
};

export default InputField;

import React from "react";
import "../index.css";
import PropTypes from "prop-types";
import InputField from "./InputField";
import backArrowIcon from "../images/back-arrow-icon.png";
import logo from "../images/logo.svg";

const Payments = ({ onBack, onShowFees, formData, onFormDataChange }) => {
  const parseAmount = (value) => {
    return parseFloat(value.replace(/[^0-9.-]+/g, "")) || 0;
  };

  const updateBalloonFields = (updatedFormData) => {
    const purchasePrice = parseAmount(updatedFormData.purchasePrice);
    const cashDeposit = parseAmount(updatedFormData.cashDeposit);
    const tradeIn = parseAmount(updatedFormData.tradeIn);
    const payoutAmount = parseAmount(updatedFormData.payoutAmount);

    const balloonAmount = purchasePrice - cashDeposit - tradeIn + payoutAmount;

    const balloonPayment = parseAmount(updatedFormData.balloonPayment);
    const balloonPercentage = parseAmount(
      updatedFormData.balloonPaymentPercentage,
    );

    if (balloonAmount > 0) {
      if (
        updatedFormData.balloonPaymentPercentage !==
        formData.balloonPaymentPercentage
      ) {
        const calculatedPayment = (balloonPercentage / 100) * balloonAmount;
        updatedFormData.balloonPayment = isNaN(calculatedPayment)
          ? ""
          : calculatedPayment.toFixed(2);
      } else if (updatedFormData.balloonPayment !== formData.balloonPayment) {
        const calculatedPercentage = (balloonPayment / balloonAmount) * 100;
        updatedFormData.balloonPaymentPercentage = isNaN(calculatedPercentage)
          ? ""
          : calculatedPercentage.toFixed(2);
      }
    } else {
      updatedFormData.balloonPayment = "";
      updatedFormData.balloonPaymentPercentage = "";
    }

    return updatedFormData;
  };

  const handleInputChange = (eventOrName, valueOrChecked) => {
    let name, value;
    if (typeof eventOrName === "string") {
      // This is for toggle switches
      name = eventOrName;
      value = valueOrChecked;
    } else if (eventOrName && eventOrName.target) {
      // This is for regular inputs
      ({ name, value } = eventOrName.target);
    } else {
      console.error("Invalid event passed to handleInputChange:", eventOrName);
      return;
    }

    let updatedFormData = { ...formData, [name]: value };

    if (
      name === "balloonPayment" ||
      name === "balloonPaymentPercentage" ||
      name === "purchasePrice" ||
      name === "cashDeposit" ||
      name === "tradeIn" ||
      name === "payoutAmount"
    ) {
      updatedFormData = updateBalloonFields(updatedFormData);
    }

    onFormDataChange(updatedFormData);
  };

  const formatMoney = (value) => {
    const number = parseFloat(value);
    return isNaN(number)
      ? ""
      : `$${number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  };

  const formatPercentage = (value) => {
    const number = parseFloat(value);
    return isNaN(number) ? "" : `${number.toFixed(2)}%`;
  };

  const handleInputBlur = (event) => {
    const { name, value } = event.target;
    let formattedValue = value;

    if (name === "balloonPayment") {
      formattedValue = formatMoney(value);
    } else if (name === "balloonPaymentPercentage") {
      formattedValue = formatPercentage(value);
    }

    onFormDataChange({ ...formData, [name]: formattedValue });
  };

  Payments.propTypes = {
    onBack: PropTypes.func.isRequired,
    onShowFees: PropTypes.func.isRequired,
    formData: PropTypes.object.isRequired,
    onFormDataChange: PropTypes.func.isRequired,
  };
  const TermOptions = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
  ];

  const inputFields = [
    {
      name: "loanTerm",
      label: "Loan Term",
      placeholder: "Enter loan amount",
      type: "radio",
      options: TermOptions,
    },
    {
      name: "balloonPayment",
      label: "Balloon Payment",
      placeholder: "Enter Balloon Payment",
      type: "money",
    },
    {
      name: "balloonPaymentPercentage",
      label: "Balloon Payment (%)",
      placeholder: "Enter Balloon Payment",
      type: "percentage",
    },
    {
      name: "paymentFrequency",
      label: "Payment Frequency",
      type: "toggle",
      toggle: {
        options: ["Monthly", "Weekly"],
        initialOption: formData.paymentFrequency || "Monthly", // Set default to 'Monthly'
      },
      onChange: (name, value) =>
        onFormDataChange({ name: name, paymentFrequency: value }),
    },
    {
      name: "paymentTiming",
      label: "Payment Timing",
      type: "toggle",
      toggle: {
        options: ["Advance", "Arrears"],
        initialOption: formData.paymentTiming || "Arrears",
      },
      onChange: (name, value) =>
        onFormDataChange({ name: name, paymentTiming: value }),
    },
  ];

  // Log the formData for debugging
  console.log("Current formData:", formData);

  return (
    <div className="iphone">
      <div className="div">
        <div
          style={{
            width: 26,
            height: 26,
            left: 3,
            top: 3,
            position: "relative",
            background: "white",
          }}
          onClick={onBack}
        >
          <img alt="Back" src={backArrowIcon} />
        </div>

        <div className="navbar">
          <div className="navbar-item">
            <div className="ellipse" />
            <div className="text-wrapper-6">Loan</div>
          </div>
          <div className="navbar-item">
            <div className="ellipse-2 solid" />
            <div className="text-wrapper-7">Payment</div>
          </div>
          <div className="navbar-item">
            <div className="ellipse-3" />
            <div className="text-wrapper-8">Fees</div>
          </div>
          <div className="navbar-item">
            <div className="ellipse-4" />
            <div className="text-wrapper-9">Quote</div>
          </div>
          <div className="line" />
          <div className="line-2" />
        </div>

        <div className="text-wrapper-4">How payments are made!</div>

        <div className="frame">
          {inputFields.map((field, index) => (
            <InputField
              key={index}
              name={field.name}
              label={field.label}
              placeholder={field.placeholder}
              type={field.type}
              options={field.options}
              toggle={field.toggle}
              value={formData[field.name] || ""}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
            />
          ))}
        </div>

        <div className="overlap" onClick={onShowFees}>
          <div className="text-wrapper-2">Next →</div>
        </div>

        <div className="group">
          <div className="text-wrapper-5">Powered by</div>
          <img className="image" alt="Powered by logo" src={logo} />
        </div>
      </div>
    </div>
  );
};

export default Payments;

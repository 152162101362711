// This is for Desktop only
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import EmailQuoteModal from "./EmailQuoteModal";
import InfoBox from "./InfoBox";
import * as payments from "../modules/payments";

const QuoteSummary = ({ formData, brokerageAmount }) => {
  QuoteSummary.propTypes = {
    formData: PropTypes.object.isRequired,
    brokerageAmount: PropTypes.number,
    netAmountFinanced: PropTypes.number,
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [loanSummary, setLoanSummary] = useState({});

  // Helper function to clean and parse input
  const parseAmount = (value) => {
    if (typeof value === "string") {
      // Remove all non-numeric characters except for the decimal point
      return parseFloat(value.replace(/[^0-9.]/g, "")) || 0;
    }
    return parseFloat(value) || 0;
  };

  console.log("formData", formData);
  const purchasePrice = parseAmount(formData.purchasePrice);
  const cashDeposit = parseAmount(formData.cashDeposit);
  const tradeIn = parseAmount(formData.tradeIn);
  const payoutAmount = parseAmount(formData.payoutAmount);
  const balloonPercentage = parseAmount(formData.balloonPercentage);
  const balloonAmount = parseAmount(formData.balloonAmount);
  const brokeragePercentage = parseAmount(formData.brokeragePercentage);
  const financierRate = parseAmount(formData.financierRate);
  const term = parseInt(formData.term) || 1;
  const paymentCycle = formData.paymentCycle || "Monthly";
  const paymentTime = formData.paymentTime || "Arrears";

  // Calculate fees
  const originationFee = parseAmount(formData.brokerOriginationFees);
  console.log("originationFee", originationFee);
  const originationFeeWithGST = originationFee * 1.1;
  console.log("originationFeeWithGST", originationFeeWithGST);
  const loanApplicationFee = parseAmount(formData.loanApplicationFee);
  console.log("loanApplicationFee", loanApplicationFee);
  const loanApplicationFeeWithGST = loanApplicationFee * 1.1;
  console.log("loanApplicationFeeWithGST", loanApplicationFeeWithGST);
  // Calculate net amount financed
  let netAmountFinanced = purchasePrice - cashDeposit - tradeIn + payoutAmount;

  // Calculate balloon payment
  const balloonPayment =
    balloonAmount || (balloonPercentage / 100) * netAmountFinanced || 0;

  // Calculate brokerage with GST then add the applciation fee and origination fee with gst if they are financed.

  const brokerage =
    brokerageAmount || (brokeragePercentage / 100) * netAmountFinanced || 0;

  // add gst to the brokerage
  let brokerageWithGST = brokerage * 1.1;
  console.log("brokerageWithGST", brokerageWithGST);

  console.log(
    "check if finance brokerage is checked:",
    formData.financeBrokerageFee,
  );

  // add this to the total loan amount.
  if (formData.financeApplicationFee) {
    console.log("Loan Application Fee with GST", loanApplicationFeeWithGST);
    netAmountFinanced += loanApplicationFeeWithGST;
  }

  console.log("netAmountFinanced after application fee", netAmountFinanced);
  console.log(
    "check if finance brokerage is checked:",
    formData.financeBrokerageFee,
  );

  // This here adds the origination fee to the net amount financed. Then the brokerage is added with GST resulting in the change.
  if (formData.financeBrokerageFee) {
    console.log("Origination Fee with GST", originationFeeWithGST);
    netAmountFinanced += originationFeeWithGST;
  }
  console.log("netAmountFinanced after application fee", netAmountFinanced);

  // Total loan amount this should be the net finance amount including all the fees if they are financed in. Plus the brokerage with gst on it.
  // this will stop multiple gst being added.
  const totalLoanAmount = netAmountFinanced + brokerageWithGST;

  // Calculate repayments
  const paymentsPerYear = paymentCycle === "Monthly" ? 12 : 52;
  const totalPayments = term * paymentsPerYear;
  const ratePerPeriod = financierRate / 100 / paymentsPerYear;

  const timing = paymentTime === "Arrears" ? 0 : 1;

  // logout values used to create repayment
  console.log("##########################################");
  console.log(
    "This is the state of the formData before calculating the repayment",
  );
  console.log("ratePerPeriod", ratePerPeriod);
  console.log("totalPayments", totalPayments);
  console.log("totalLoanAmount", totalLoanAmount);
  console.log("brokerage", brokerageWithGST);
  console.log("balloonPayment", balloonPayment);
  console.log("timing", timing);
  console.log("totalLoanAmount", totalLoanAmount);

  console.log("##########################################");
  const repayment =
    payments.pmt(
      ratePerPeriod,
      totalPayments,
      -totalLoanAmount,
      balloonPayment,
      timing,
    ) || 0;

  console.log("This is the repayment amount. ", repayment);

  // Calculate total repayments Total repayments does not include the balloon payment, or the broker origination fee or the brokerage fee
  const totalRepayments = repayment * totalPayments;
  // lets make sure we are calculating the total repayments correctly.
  console.log(
    "totalRepayments calculation before calcuating commision",
    totalRepayments,
  );

  let interestSum = 0;
  for (let period = 0; period <= totalPayments; period++) {
    interestSum += Math.abs(
      payments.ipmt(ratePerPeriod, period, totalPayments, -totalLoanAmount),
    );
  }
  console.log("interestSum", interestSum);
  // Calculate total interest
  const totalInterest = totalRepayments - totalLoanAmount;

  // Calculate broker earnings
  const questEarnings = interestSum * (0.03 / (financierRate / 100)) || 0;
  console.log("Quest Earnings Calculation:");
  console.log("Total Interest:", totalInterest);
  console.log("Financier Rate:", financierRate);
  console.log("Quest Earnings:", questEarnings);

  console.log("questEarnings", questEarnings);
  //PRIOR TO CALCULATING HO much a vendor can earn lets dipslay the balloon payment
  console.log("Before vendor calculation", balloonPayment);
  const commisionToBroker = (() => {
    const result = interestSum - questEarnings;
    return isNaN(result) ? 0 : result;
  })();

  // Helper function to format money
  const formatMoney = (amount) => {
    return new Intl.NumberFormat("en-AU", {
      style: "currency",
      currency: "AUD",
    }).format(amount);
  };

  useEffect(() => {
    const updatedLoanSummary = {
      purchasePrice: formatMoney(purchasePrice),
      cashDeposit: formatMoney(cashDeposit),
      tradeIn: formatMoney(tradeIn),
      payoutAmount: formatMoney(payoutAmount),
      netAmountFinanced: formatMoney(netAmountFinanced),
      originationFeeWithGST: formatMoney(originationFeeWithGST),
      loanApplicationFeeWithGST: formatMoney(loanApplicationFeeWithGST),
      totalLoanAmount: formatMoney(totalLoanAmount),
      balloonPayment: formatMoney(balloonPayment),
      brokerageWithGST: formatMoney(brokerageWithGST),
      paymentCycle: paymentCycle,
      paymentTime: paymentTime,
      repayment: formatMoney(repayment),
      totalInterest: formatMoney(totalInterest),
      brokerEarnings: formatMoney(questEarnings),
    };

    setLoanSummary(updatedLoanSummary);
  }, [
    purchasePrice,
    cashDeposit,
    tradeIn,
    payoutAmount,
    netAmountFinanced,
    originationFeeWithGST,
    loanApplicationFeeWithGST,
    totalLoanAmount,
    balloonPayment,
    brokerage,
    brokerageWithGST,
    paymentCycle,
    paymentTime,
    repayment,
    totalInterest,
    questEarnings,
    commisionToBroker,
  ]);

  return (
    <div className="quote-summary">
      <h2 style={{ color: "#3D1C80" }}>Quote Summary</h2>
      <div className="summary-item">
        <span>Purchase Price</span>
        <span style={{ color: "#3D1C80" }}>{loanSummary.purchasePrice}</span>
      </div>
      <div className="summary-item">
        <span>Less Deposit</span>
        <span style={{ color: "#3D1C80" }}>{loanSummary.cashDeposit}</span>
      </div>
      <div className="summary-item">
        <span>Less Trade In</span>
        <span>{loanSummary.tradeIn}</span>
      </div>
      <div className="summary-item">
        <span>Plus Payout</span>
        <span>{loanSummary.payoutAmount}</span>
      </div>
      {/* {formData.financeBrokerageFee && (
        <div className="summary-item">
          <span>Plus Financed  Fee (incl. GST)</span>
          <span>${loanSummary.originationFeeWithGST}</span>
        </div>
      )}
      {formData.financeApplicationFee && (
        <div className="summary-item">
          <span>Financed Application Fee (incl. GST)</span>
          <span>${loanSummary.loanApplicationFeeWithGST}</span>
        </div>
      )} */}
      <div className="summary-item total">
        <span>Total Loan </span>
        <span style={{ color: "#3D1C80" }}>{loanSummary.totalLoanAmount}</span>
      </div>
      <div className="summary-item">
        <span>Balloon Payment</span>
        <span style={{ color: "#3D1C80" }}>{loanSummary.balloonPayment}</span>
      </div>
      <div className="summary-item">
        <span>Brokerage (incl. GST)</span>
        <span style={{ color: "#3D1C80" }}>{loanSummary.brokerageWithGST}</span>
      </div>
      <div className="summary-item">
        <span>Lender Application Fee (incl. GST)</span>
        <span style={{ color: "#3D1C80" }}>
          {loanSummary.loanApplicationFeeWithGST}
        </span>
      </div>
      <div className="summary-item">
        <span>Origination Fee (incl. GST)</span>
        <span style={{ color: "#3D1C80" }}>
          {loanSummary.originationFeeWithGST}
        </span>
      </div>
      <div className="summary-item">
        <span>
          {loanSummary.paymentCycle} Repayment ({loanSummary.paymentTime})
        </span>
        <span style={{ color: "#3D1C80" }}>
          {loanSummary.repayment || "$0.00"}
        </span>
      </div>
      <InfoBox
        formData={formData}
        totalCommission={
          commisionToBroker === 0
            ? "$0.00"
            : Number(commisionToBroker.toFixed(2))
        }
      />
      <button
        className="email-quote-button"
        onClick={openModal}
        style={{
          backgroundColor: "#05dd80",
          color: "white",
          padding: "10px 20px",
          borderRadius: "5px",
          border: "none",
          cursor: "pointer",
          fontSize: "16px",
          fontWeight: "bold",
          marginTop: "20px",
          width: "100%",
        }}
      >
        Email Quote
      </button>

      <EmailQuoteModal
        isOpen={isModalOpen}
        onClose={closeModal}
        emailAddress={emailAddress}
        setEmailAddress={setEmailAddress}
        formJson={loanSummary}
      />
    </div>
  );
};

export default QuoteSummary;
